.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: red;
}

.phone {
  position: fixed;
  z-index: 1030;
  right: 30px;
  bottom: 30px;
  background: red;
  color: white;
  border-radius: 10px;
  padding: 5px;
}

.phone a {
  color: white;
}

.logo {
  background: #000;
}

.card-img-top {
  min-height: 208px;
}